/*******************************
        User Overrides
*******************************/
@import "../globals/site.overrides";

@sfaPopupBackground: rgba(12,0,51,1);

.ui.bottom.left.popup.filterPopup {
    padding: 8px;
    width: 272px;
    z-index: 11;
}

.ui.bottom.left.popup.filterPopupOther {
    padding: 8px;
    width: 272px;
    height: 258px !important;
    z-index: 11;
}

.ui.popup.sfaPopup {
    text-align: center;
    .caption-text;
    .white-text;
    background: @sfaPopupBackground;
    color: contrast(@sfaPopupBackground, @black, @white);
    border: none;
}

.ui.right.center.popup.sfaPopup:before,
.ui.left.center.popup.sfaPopup:before,
.ui.bottom.popup.sfaPopup:before,
.ui.top.popup.sfaPopup:before {
  background: @sfaPopupBackground;
}
