/*******************************
         Site Overrides
*******************************/

@import "../globals/site.overrides";

.ui.header.title {
    .title-text;
    margin-right: 16px;
    vertical-align: middle;
    align-self: center;
    margin-top: 0;
    margin-bottom: 0;
}