/*******************************
         Site Overrides
*******************************/
@import "../globals/site.overrides";

.ui.modal > .header {
    text-align: center;
    border-bottom: none;
}

.ui.modal > .actions {
    background: @formBackground;
    color: contrast(@formBackground, @black, @white);
}

.ui.modal > .actions > .ui.button {
    font-weight: 700;
}

.ui.modal > .close {
    top: @innerCloseTop;
    right: @innerCloseRight;
    color: @innerCloseColor;
}

.ui.modal.nopadding .content {
    padding: 0;
}

.ui.modal.nopadding form {
    padding: @contentPadding;
}

/*
* fix for modal showing in top left
* https://github.com/Semantic-Org/Semantic-UI/issues/6267
*/
.ui.small.modal,
.visible.transition {
  margin-top: auto !important;
  display: inline-block !important;
  position: relative;
  margin: auto;
}

@media only screen and (max-width : @largestMobileScreen) {
    .ui.modal.nopadding .content {
        padding: 0 !important;
    }

    .ui.modal.nopadding form {
        display: block;
        padding: @mobileContentPadding !important;
    }

    .ui.modal.nopadding > .header {
        padding: @mobileHeaderPadding !important;
        padding-right: @closeHitbox !important;
    }
}