/*******************************
         Site Overrides
*******************************/

.ui.table thead th {
    padding: 4px 0 !important;
    line-height: 3;
}

.ui.basic.table th.sortable:hover,
.ui.selectable.table tbody tr:hover,
.ui.table tbody tr td.selectable:hover {
    background: @hoverColor !important;
    color: contrast(@hoverColor, @black, @white);
}

.ui.basic.table th {
    height: 48px;
    background: transparent !important;
}

.ui.basic.table th.sorted {
   background: @sortedColor !important;
   color: contrast(@sortedColor, @black, @white);
}

.ui.table td {
    padding-left: 0;
}

.ui.tiny.table {
    font-size: .8em;
}