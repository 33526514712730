/*******************************
        Site Overrides
*******************************/
@import "../globals/site.overrides";

.ui.message > .close.icon {
    color: @white;
    opacity: 1;
    margin-left: 1em;
    margin-top: 0.3em;
}

.ui.icon.message > .icon:not(.close) {
    vertical-align: top;
    align-self: flex-start;
    font-size: 1.6em;
    opacity: 1;
    margin-right: 1em;
    margin-top: 0.1em;
    line-height: 1em;
    background-color: transparent;
    color: @white;
}

.ui.icon.message > .content {
    color: @white;
    align-self: flex-start;
    vertical-align: top;
    padding-top: 0;
}

.ui.red.message {
    color: @white;
    background-color: @red;
}

.ui.green.message {
    color: @white;
    background-color: @green;
}

.ui.orange.message {
    color: @white;
    background-color: @orange;
}

.ui.message > .content {
    .subheader-text;
    .bold;
}