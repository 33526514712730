/*******************************
         Site Overrides
*******************************/

.ui.vertical.menu .item:before {
    height: 0px;
}

.ui.vertical.menu .item {
    padding-top: 5px;
    padding-bottom: 5px;
}

.ui.secondary.menu .item.filterItem:hover,
.ui.secondary.menu .active.item.filterItem {
    background: @blue;
    color: @white;
    min-width: 130px;
}

.ui.secondary.menu .item.filterItem {
    min-width: 130px;
    min-height: 40px;
}

.ui.pagination.menu .active.item {
    background: @active;
    color: @white;
}