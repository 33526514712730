/*******************************
         Site Overrides
*******************************/

@import "../globals/site.overrides";

.ui.form input:not([type]), 
.ui.form input[type=date], 
.ui.form input[type=datetime-local], 
.ui.form input[type=email], 
.ui.form input[type=file], 
.ui.form input[type=number], 
.ui.form input[type=password], 
.ui.form input[type=search], 
.ui.form input[type=tel], 
.ui.form input[type=text], 
.ui.form input[type=time], 
.ui.form input[type=url] {
    .subheader-text
}

.ui.form textarea {
    .subheader-text
}
