/*******************************
         Site Overrides
*******************************/
.ui.celled.grid > .column.nopadding:not(.row),
.ui.celled.grid > .row.nopadding>.column {
    padding: 0em;
    box-shadow: none;
}

.ui.celled.grid > .row.separator:first-child {
    margin-top: 10px;
    padding-top: 10px;
    box-shadow: 0px -2px 0px 0px @celledBorderColor;
}
