/*******************************
         Site Overrides
*******************************/
@import "../globals/site.overrides";

.ui.progress .bar {
  background: @blue;
}

/* use the following override to set the min bar length to 0 (i.e. 0% -> 0 length)
.ui.progress .bar {
  min-width: 0;
}
*/
