/*******************************
         Site Overrides
*******************************/

@import "../globals/site.overrides";

.ui.button.sign-out {
    opacity: 0.5;
    background: #F0F0F0;
    color: contrast(#F0F0F0, @black, @white);
    border-radius: 20px;
    margin-bottom: 10px;
    line-height: 20px;
}
.ui.button.sign-out:hover{
    opacity:1;
    background: #3599db;
    color: white;
   
}
.ui.button.transparent-btn {
    background: transparent;
    color: @active;
}

.ui.button.transparent-btn:hover {
    color: @lightBlue;
}

.ui.button.transparent-btn.black-text {
    color: @black;
}

.ui.button.transparent-btn.black-text:hover {
   color: #58a3f8!important;
}

.ui.button.transparent-btn.clear-all {
    color: @black;
    opacity: 0.5;
}

.ui.button.transparent-btn.clear-all:hover {
    color: @black;
    opacity: 1;
}

.ui.button.link-button {
    background: transparent;
    color: @active;
    padding: 0;
    margin: 0;
    &:hover {
        color: @lightBlue;
    }
    &.black-text {
      color: @black;
    }
}


.ui.button.link-button

.ui.button.link-button.black-text:hover {
   color: #58a3f8!important;
}

.ui.button.link-button.clear-all {
    color: @black;
    opacity: 0.5;
}

.ui.button.link-button.clear-all:hover {
    color: @black;
    opacity: 1;
}


.ui.button.white,
.ui.circular.button.white {
    background: @white;
}

.ui.circular.button.small {
    padding: 0.75em;
    height: 45px;
    width: 45px;
    vertical-align: middle;
    align-self: center;
}

.ui.circular.button > span {
    color: @black;
}

.ui.button.popup-btn {
    margin-top: 10px;
    height: 29px;
    font-size: 14px;
    .bold;
    .white-text;
    background: rgba(255,255,255,0.5);
}

.ui.button {
    height: 40px;
    .body-text;
    line-height: 0;
    font-weight: 400;
}

.ui.primary.button, .ui.primary.buttons .button,
.ui.primary.button:hover, .ui.primary.buttons .button:hover {
    color: contrast(@primaryColor, @black, @white);
}

.ui.secondary.button, .ui.secondary.buttons .button,
.ui.secondary.button:hover, .ui.secondary.buttons .button:hover {
    color: contrast(@secondaryColor, @black, @white);
}

.ui.positive.buttons .button,
.ui.positive.button {
  background-color: @green;
  color: contrast(@green, @black, @white);
}

.ui.basic.button, .ui.basic.buttons .button {
  color: rgba(0,0,0,1)!important;
}