/*******************************
         Site Overrides
*******************************/

.rounded {
    border-radius: 20px;
}

.button-like {
    text-align: center;
    width: fit-content;
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}

/*
* Typography Overrides
*/
.font-regular (@fontSize, @lineHeight) {
    font-family: Open Sans;
    font-size: @fontSize;
    letter-spacing: 0;
    line-height: @lineHeight;
    color: @black;
}

.small-text {
    .font-regular(10px, 16px);
}

.caption-text {
    .font-regular(12px, 16px);
}

.tiny-caption-text {
    .font-regular(10px, 16px);
}

.body-text {
    .font-regular(1rem, 20px);
}

.subheader-text {
    .font-regular(16px, 24px);
}

.title-text {
    .font-regular(20px, 32px);
}

.headline-text {
    .font-regular(24px, 36px);
}

.display-text {
    .font-regular(36px, 54px);
}

.jumbo-text {
    .font-regular(48px, 64px);
}

.mega-text {
    .font-regular(72px, 96px);
}

.bold {
    font-family: Open Sans Bold;
}


/*
* these needed to differeniate from text-color
*/
.secondary-color {
    opacity: 0.5;
}

.tertiary-color {
    opacity: 0.2;
}

/*
* create backgrounds and text colours
*/
@actions: success, warning, error, primary, secondary;

.-(@i: length(@actions)) when (@i > 0) {
    @name: extract(@actions, @i);
    .@{name} {background: @@name; color: contrast(@@name, @black, @white);}
  	.@{name}-text {color: @@name;}
    .-((@i - 1));
} .-;

.generateAccents(3);

.generateAccents(@counter) when (@counter > 0) {
  .generateAccents((@counter - 1));
  .accent-@{counter} {color:  ~"@{accent@{counter}}";};
  .bg-accent-@{counter} {background:  ~"@{accent@{counter}}";};
}

.active-text {
    color: @active
}

.bg-white {
    background: @white;
    color: @black;
}

.white-text {
    color: @white !important;
}

.bg-black {
    background: @black;
    color: @white !important;
}

.black-text {
    color: @black !important;
}

.red-text {
    color: @red !important;
}

.blue-text {
    color: @blue !important;
}

.bg-yellow {
    background: @yellow;
    color: contrast(@yellow, @black, @white);
}

/*
* Element Overrides
*/ 
.card {
    .bg-white;
}

.card.active {
    .primary-text;
    border-bottom: @blue 5px solid;
    border-radius: 5px;
}

.card.active:after {
    border-top: 20px solid @blue;
}

.half-opaque-text {
    .secondary-color;
    .black-text;
    line-height: 16px;
}

input::-webkit-input-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
input:-moz-placeholder { /* Firefox 18- */
    .black-text
}


/*
* OBS CONTROLS
*/
.obs-input-wrapper {
    display: block;
    width: 88.8%;
    margin: 0 auto 10px;
}

.obs-input {
    .bg-white;
    width: 100%;
    height: 40px;
    box-shadow: 0 4px 6px 0 rgba(118,118,118,0.50);
    border-radius: 3px;
    text-align: center;
}

body {
    background: @gradient1;
    color: contrast(@gradient1, @black, @white);
}

a,
a:hover {
    .body-text;
    color: @active;
}

.bg-translucent-black {
    background: @translucentBackground;
    color: @black;
}

.width-fill-parent {
    width: 100%;
}

.height-fill-parent {
    height: 100%;
}

/**
Layout
*/
.container {
    border-radius: 3px;
}

.container.padded {
    padding: 20px;
}

.container.padded.padding-10 {
    padding: 10px;
}

.container.padded.padding-5 {
    padding: 5px;
}

.flex .align-self-end {
    align-self: flex-end;
}

