/*******************************
         Site Overrides
*******************************/
@import "../globals/site.overrides";

.form-footer {
    background: @formBackground;
    color: contrast(@formBackground, @black, @white);
}

.ui.form .field .readonly > label {
  .caption-text;
  .secondary-color;
}

.ui.form .field > label {
  .body-text;
  .secondary-color;
}

.ui.form .field .readonly {
  .subheader-text
}

// fix for IE since IE 11 doesn't support fit-content
.ui.form .field .readonly > div {
  display: inline-block;
}
